@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  @apply bg-black/10;
  font-family: "Poppins", sans-serif;
}

.Hover__Icon:hover svg {
  @apply !translate-x-1;
}

.Right__Slider .slick-list {
  @apply h-full;
}

.Right__Slider .slick-dots {
  @apply right-0 bg-slate-200 !w-fit -top-2 !flex !flex-col !h-full !justify-center !m-0 !p-0;
}

.slick-slider img {
  @apply !h-full;
}

button {
  outline: none;
  border: none;
}

#Menu button {
  @apply bg-transparent shadow-none outline-none;
}

.slick-prev {
  left: 20px !important;
  z-index: 30;
}

.slick-prev::before {
  color: black !important;
}

.slick-next {
  right: 22px !important;
}

.slick-next::before {
  color: black !important;
}

.filter__image {
  filter: brightness(0) invert(1);
}
